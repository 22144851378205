import type { FC } from "react"
interface Props {
  handleOpen: any
  data: any
  keyData: string
  oddsPnlData: {
    [x: number]: number
  }
}

const BookMakerClasic: FC<Props> = ({
  handleOpen,
  data,
  keyData,
  oddsPnlData,
}) => {
  const groupByProviderTypeNation = (bookmakerData: any) => {
    const { result } = bookmakerData.reduce(
      (acc: any, item: any) => {
        const { mid, t } = item

        if (!acc.result[t]) {
          acc.result[t] = {}
        }

        if (!acc.result[t][mid]) {
          acc.result[t][mid] = []
        }

        acc.result[t][mid].push(item)
        // }

        return acc
      },
      { result: {} },
    )
    return { ...result }
  }

  const groupedData = groupByProviderTypeNation(data)

  return (
    <>
      <div className="remove-margin-on-mobile" style={{ marginBottom: 40 }}>
        {Object.keys(groupedData)?.map(keys => {
          if (keys?.toLowerCase() !== "bookmaker") return null
          return (
            <table className="table" style={{ marginBottom: 0 }}>
              <thead>
                <tr>
                  <th>
                    {/* <span style={{ fontSize: "13px" }}>{keys}</span>  */}
                    Team (
                    {data && data[0]?.maxBet})
                  </th>
                  <th className="tc">lagai</th>
                  <th className="tc">khai</th>
                  <th className="tc">position</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedData[keys])?.map(midKey => {
                  return groupedData[keys][midKey]?.map(item => {
                    return (
                      <tr
                        className={`calBack `}
                        style={{ position: "relative" }}
                      >
                        <td>
                          <p>{item?.nation} </p>
                        </td>

                        <td
                          className="tc session_not book_yex"
                          onClick={() =>
                            item?.gstatus.toLowerCase() !== "suspended" &&
                            handleOpen(
                              false,
                              true,
                              item?.b1,
                              keyData,
                              item?.sid,
                              item?.bs1,
                              item?.mid,
                              item?.nation,
                              "lagai",
                              new Date(),
                            )
                          }
                        >
                          {" "}
                          {item?.b1}
                        </td>

                        <td
                          className={`tc session_yes book_yex fancy_book ${item?.gstatus.toLowerCase()}`}
                          onClick={() =>
                            item?.gstatus.toLowerCase() !== "suspended" &&
                            handleOpen(
                              false,
                              false,
                              item?.l1,
                              keyData,
                              item?.sid,
                              item?.ls1,
                              item?.mid,
                              item?.nation,
                              "khai",
                              new Date(),
                            )
                          }
                        >
                          {" "}
                          {item?.l1}
                        </td>

                        <td
                          className="tc"
                          style={{
                            color:
                              oddsPnlData[parseInt(item?.sid)] > 0
                                ? "green"
                                : "red",
                          }}
                        >
                          {oddsPnlData[parseInt(item?.sid)] || 0}
                        </td>
                        {/* {item?.gstatus.toLowerCase() === "suspended" && <div className="sus_com_cl">{item?.gstatus}</div>} */}
                      </tr>
                    )
                  })
                })}
                {/* {data.map((item: any) => {
                  return (
                    <tr className={`calBack `} style={{ position: "relative" }}>
                      <td>
                        <p>{item?.nation} </p>
                      </td>

                      <td
                        className="tc session_not book_yex"
                        onClick={() =>
                          item?.gstatus.toLowerCase() !== "suspended" &&
                          handleOpen(
                            false,
                            true,
                            item?.b1,
                            keyData,
                            item?.sid,
                            item?.bs1,
                            item?.mid,
                            item?.nation,
                            "lagai",
                            new Date(),
                          )
                        }
                      >
                        {" "}
                        {item?.b1}
                      </td>

                      <td
                        className={`tc session_yes book_yex fancy_book ${item?.gstatus.toLowerCase()}`}
                        onClick={() =>
                          item?.gstatus.toLowerCase() !== "suspended" &&
                          handleOpen(
                            false,
                            false,
                            item?.l1,
                            keyData,
                            item?.sid,
                            item?.ls1,
                            item?.mid,
                            item?.nation,
                            "khai",
                            new Date(),
                          )
                        }
                      >
                        {" "}
                        {item?.l1}
                      </td>

                      <td
                        className="tc"
                        style={{
                          color:
                            oddsPnlData[parseInt(item?.sid)] > 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {oddsPnlData[parseInt(item?.sid)] || 0}
                      </td>
                    </tr>
                  )
                })} */}
              </tbody>
            </table>
          )
        })}
      </div>
    </>
  )
}

export default BookMakerClasic
