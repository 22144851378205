import type { FC } from "react"
import React from "react"
interface Props {
  handleOpen: any
  data: any
  keyData: string
  oddsPnlData: {
    [x: number]: number
  }
}

const BookMaker: FC<Props> = ({ handleOpen, data, keyData, oddsPnlData }) => {
  const groupByProviderTypeNation = (bookmakerData: any) => {
    const { result } = bookmakerData.reduce(
      (acc: any, item: any) => {
        const { mid, t } = item

        if (!acc.result[t]) {
          acc.result[t] = {}
        }

        if (!acc.result[t][mid]) {
          acc.result[t][mid] = []
        }

        acc.result[t][mid].push(item)
        // }

        return acc
      },
      { result: {} },
    )
    return { ...result }
  }

  const groupedData = groupByProviderTypeNation(data)

  return (
    <>
      <div className="mt-4">
        {Object.keys(groupedData)?.map(keys => {
          if (keys?.toLowerCase() !== "bookmaker") return null
          return (
            <table className="table second-table">
              <thead>
                <tr>
                  <th>
                    {/* <span
                      style={{
                        display: "block",
                        fontSize: "14px",
                        color: "#000",
                        opacity: "0.8",
                      }}
                    >
                      {keys}
                    </span> */}
                    TEAM: MAX {data && data[0]?.maxBet}
                  </th>
                  <th>LAGAI</th>
                  <th>KHAI</th>
                  <th>POSITION</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedData[keys])?.map(midKey => {
                  return groupedData[keys][midKey]?.map(item => {
                    return (
                      <tr
                        className={`gryBck `}
                        style={{ position: "relative" }}
                      >
                        <td className="cell-odds" style={{ width: "60%" }}>
                          {item?.nation}
                        </td>

                        <td
                          className="cell-blue"
                          onClick={() =>
                            item?.gstatus.toLowerCase() !== "suspended" &&
                            handleOpen(
                              false,
                              true,
                              item?.b1,
                              keyData,
                              item?.sid,
                              item?.bs1,
                              item?.mid,
                              item?.nation,
                              "LAGAI",
                              new Date(),
                            )
                          }
                        >
                          {item.b1}
                        </td>

                        <td
                          className={`cell-red fancy_book ${item?.gstatus.toLowerCase()}`}
                          onClick={() => {
                            item?.gstatus.toLowerCase() !== "suspended" &&
                              handleOpen(
                                false,
                                false,
                                item?.l1,
                                keyData,
                                item?.sid,
                                item?.ls1,
                                item?.mid,
                                item?.nation,
                                "KHAI",
                                new Date(),
                              )
                          }}
                        >
                          {item.l1}
                        </td>

                        <td className="text-center" style={{ fontWeight: 600 }}>
                          <span
                            style={{
                              color:
                                oddsPnlData[parseInt(item?.sid)] > 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {oddsPnlData[parseInt(item?.sid)] || 0}
                          </span>
                        </td>
                        {/* {item?.gstatus.toLowerCase() === "suspended" && <div className="sus_com">{item?.gstatus}</div>} */}
                      </tr>
                    )
                  })
                })}
                {/* {data.map((item: any) => {
                  return (
                    <tr className={`gryBck `} style={{ position: "relative" }}>
                      <td className="cell-odds" style={{ width: "60%" }}>
                        {item?.nation}
                      </td>

                      <td
                        className="cell-blue"
                        onClick={() =>
                          item?.gstatus.toLowerCase() !== "suspended" &&
                          handleOpen(
                            false,
                            true,
                            item?.b1,
                            keyData,
                            item?.sid,
                            item?.bs1,
                            item?.mid,
                            item?.nation,
                            "LAGAI",
                            new Date(),
                          )
                        }
                      >
                        {item.b1}
                      </td>

                      <td
                        className={`cell-red fancy_book ${item?.gstatus.toLowerCase()}`}
                        onClick={() => {
                          item?.gstatus.toLowerCase() !== "suspended" &&
                            handleOpen(
                              false,
                              false,
                              item?.l1,
                              keyData,
                              item?.sid,
                              item?.ls1,
                              item?.mid,
                              item?.nation,
                              "KHAI",
                              new Date(),
                            )
                        }}
                      >
                        {item.l1}
                      </td>

                      <td className="text-center" style={{ fontWeight: 600 }}>
                        <span
                          style={{
                            color:
                              oddsPnlData[parseInt(item?.sid)] > 0
                                ? "green"
                                : "red",
                          }}
                        >
                          {oddsPnlData[parseInt(item?.sid)] || 0}
                        </span>
                      </td>
                     
                    </tr>
                  )
                })} */}
              </tbody>
            </table>
          )
        })}
      </div>
    </>
  )
}

export default BookMaker
